import React from 'react';
import { graphql } from 'gatsby';
import Section from '../layout/Section.js';
import SEO from '../components/SEO.js';
import GridColumn from '../layout/Grid/GridColumn';
import GridContainer from '../layout/Grid/GridContainer';
import GridRow from '../layout/Grid/GridRow';
import IntroSection from '../components/IntroSection.js';
import { IconExternalLink2 } from '../components/Icons.js';
import PageBuilder from '../components/PageBuilder.js';

export default function MagazinPage({ data }) {
  return (
    <>
      <SEO title={data.page.seoTitle} description={data.page.seoDescription} />
      {/* <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <IntroSection intro={data.page} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section> */}
      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h2>Presse</h2>
              <PageBuilder elements={data.press} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}

export const query = graphql`
  query PresseQuery {
    page: sanityMainPages(slug: { current: { eq: "presse" } }) {
      headline
      seoTitle
      seoDescription
      _rawIntroCopy
    }

    press: sanityPressPageBuilder(_id: { eq: "pressPageBuilder" }) {
      ...PageBuilder
    }
  }
`;
